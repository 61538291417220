import styled from "styled-components"

const DoubleButtonsWrapper = styled.div`
  max-width: 100%;
  display: inline-flex;
  flex-direction: column;
  @media ${({ theme }) => theme.breakpoints.md} {
    flex-direction: row;
  }
  button:first-child,
  a:first-child {
    margin-bottom: 25px;
    @media ${({ theme }) => theme.breakpoints.md} {
      margin-bottom: 0;
      margin-right: 18px;
    }
    @media ${({ theme }) => theme.breakpoints.lg} {
      margin-right: 50px;
    }
  }
`

export default DoubleButtonsWrapper

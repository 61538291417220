export function printNumber(value) {
  if (!value) return 0

  // Not using .toLocaleString() anymore because Spain changed the rules of number formatting
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
}

export function numberWithDots(value) {
  if (!value) return ""
  const parsed = parseFloat(value.toString().replace(/\./g, ""))
  return parsed.toLocaleString("en", {}).replace(/,/g, ".")
}

export function printCurrency(value, decimals) {
  return Number(value).toLocaleString("es-ES", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: decimals ? 2 : 0
  })
}

export function printDate(value, locale = "es") {
  if (!value || typeof Intl === "undefined") return ""
  const d = new Date(value)
  const ye = new Intl.DateTimeFormat(locale, { year: "numeric" }).format(d)
  const mo = new Intl.DateTimeFormat(locale, { month: "long" }).format(d)
  const da = new Intl.DateTimeFormat(locale, { day: "2-digit" }).format(d)
  return `${da} ${mo}, ${ye}`
}

export function printSimpleDate(value, locale = "es") {
  if (!value || typeof Intl === "undefined") return ""
  const d = new Date(value)
  const ye = new Intl.DateTimeFormat(locale, { year: "numeric" }).format(d)
  const mo = new Intl.DateTimeFormat(locale, { month: "2-digit" }).format(d)
  const da = new Intl.DateTimeFormat(locale, { day: "2-digit" }).format(d)
  return `${da}/${mo}/${ye}`
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

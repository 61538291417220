import * as PropTypes from "prop-types"

const defaultSizes = [
  [1200, "-xl"],
  [992, "-lg"],
  [768, "-md"],
  [375, "-sm"]
]

const defaultSize = defaultSizes[3][1]

function ResponsiveImage({ url, alt, ext, highRes, sizes }) {
  return (
    <picture>
      {sizes.map(([breakpoint, suffix]) => (
        <source
          key={url + suffix}
          srcSet={`${url}${suffix}.${ext}${
            highRes
              ? `, ${url}${suffix}_2x.${ext} 2x,
                 ${url}${suffix}_3x.${ext} 3x`
              : ""
          }`}
          media={`(min-width: ${breakpoint}px)`}
        />
      ))}
      <img alt={alt} src={`${url}${defaultSize}.${ext}`} />
    </picture>
  )
}

ResponsiveImage.defaultProps = {
  ext: "png",
  highRes: true,
  sizes: defaultSizes
}

ResponsiveImage.propTypes = {
  alt: PropTypes.string.isRequired,
  ext: PropTypes.string,
  highRes: PropTypes.bool,
  url: PropTypes.string.isRequired,
  sizes: PropTypes.array
}

export default ResponsiveImage

import isEmpty from "lodash/isEmpty"
import pickBy from "lodash/pickBy"
import { EMPTY_CARS_FILTER, SET_PAGE, UPDATE_CARS_FILTER } from "./actions"

// Cars

export const updateCarsFilter = filter => ({
  type: UPDATE_CARS_FILTER,
  filter: pickBy(filter, v => v !== undefined && v !== null && !isEmpty(v))
})

export const emptyCarsFilter = () => ({ type: EMPTY_CARS_FILTER })

export const setPage = page => ({ type: SET_PAGE, page })

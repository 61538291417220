import * as PropTypes from "prop-types"
import { FAQPageJsonLd } from "next-seo"

export default function Faq({ list }) {
  return (
    <FAQPageJsonLd
      mainEntity={list.map(item => ({
        questionName: item.nameSchema || item.question,
        acceptedAnswerText: item.textSchema || item.answer
      }))}
    />
  )
}

Faq.propTypes = {
  list: PropTypes.array.isRequired
}

import { NextSeo } from "next-seo"
import * as PropTypes from "prop-types"
import { getPath } from "helpers/getPath"
import { useLocale } from "react-polyglot-hooks"

const baseUrl = "https://swipcar.com"

export default function Canonical({ page, params, hreflang }) {
  const locale = useLocale()
  const alternates = []

  if (hreflang) {
    const defaultPath = getPath("es", page, params)
    if (defaultPath !== undefined) alternates.push({ hrefLang: "x-default", href: `${baseUrl}/es${defaultPath}` })
  }

  return <NextSeo languageAlternates={alternates} canonical={`${baseUrl}/${locale}${getPath(locale, page, params)}`} />
}

Canonical.defaultProps = {
  params: {},
  hreflang: true
}

Canonical.propTypes = {
  page: PropTypes.string.isRequired,
  params: PropTypes.object,
  hreflang: PropTypes.bool
}

import * as PropTypes from "prop-types"
import DoubleButtonsWrapper from "components/styled/Buttons/DoubleButtonsWrapper"
import Link from "components/Link"
import { PrimaryButton } from "components/styled/Buttons/PrimaryButton"
import { useT } from "react-polyglot-hooks"
import { sendEvent } from "scripts/GoogleAnalytics"
import styled from "styled-components"
import { emptyCarsFilter } from "redux/actions"
import { useDispatch } from "react-redux"
import { capitalizeFirstLetter } from "helpers/formatter"

const SingleButton = styled(PrimaryButton)`
  justify-content: center;
`

const Wrapper = styled(DoubleButtonsWrapper)`
  a:first-child {
    margin-bottom: 0;
  }
`

export default function HeaderButtons({ page }) {
  const t = useT()
  const dispatch = useDispatch()

  const onClickCTA = variant => {
    dispatch(emptyCarsFilter())
    sendEvent(`Click CTA ${variant} car`, {
      event_category: page,
      event_label: `${capitalizeFirstLetter(variant)} Car header`
    })
  }

  return (
    <Wrapper>
      <Link page="stock">
        <SingleButton as="a" size="xxl" shadow onClick={() => onClickCTA("search")}>
          {t("common.view_offers")}
        </SingleButton>
      </Link>
    </Wrapper>
  )
}

HeaderButtons.propTypes = {
  page: PropTypes.string.isRequired
}

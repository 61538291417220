import * as PropTypes from "prop-types"
import Canonical from "components/Canonical"
import { NextSeo, VideoJsonLd } from "next-seo"
import { useLocale, useT } from "react-polyglot-hooks"
import { useRouter } from "next/router"
import { mainVideoId } from "helpers/constants"
import StructuredFaq from "../../structuredData/Faq"

export default function LandingSeo({ landing, faqs }) {
  const t = useT()
  const locale = useLocale()
  const { pathname } = useRouter()
  const faqArray = Object.keys(faqs).map(key => faqs[key])

  const today = new Date()
  const date = new Date(today)
  date.setDate(date.getDate() + 2)

  const videoId = mainVideoId[locale]

  return (
    <>
      <Canonical page={landing} />
      <NextSeo
        title={t(`${landing}.seo.title`, {
          month: t(`common.month_list.${date.getMonth()}`),
          year: date.getFullYear()
        })}
        description={t(`${landing}.seo.description`)}
        openGraph={{
          type: "website",
          locale: `${locale}_${locale.toUpperCase()}`,
          url: `https://swipcar.com${pathname}`,
          title: `${t(`${landing}.seo.og:title`)} | Swipcar`,
          description: t(`${landing}.seo.og:description`),
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_CLOUDINARY}/static/home/og-renting-cars.png`,
              width: 1200,
              height: 630,
              alt: t(`${landing}.seo.og:title`)
            }
          ]
        }}
      />
      {Boolean(videoId) && (
        <VideoJsonLd
          name={t("common.video.name")}
          description={t("common.video.description")}
          embedUrl={`https://www.youtube.com/embed/${videoId}`}
          thumbnailUrls={[`https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`]}
          duration={t("common.video.duration")}
          uploadDate={t("common.video.uploaded")}
        />
      )}
      {Boolean(faqArray.length) && <StructuredFaq list={faqArray} />}
    </>
  )
}

LandingSeo.defaultProps = {
  faqs: {}
}

LandingSeo.propTypes = {
  landing: PropTypes.string.isRequired,
  faqs: PropTypes.object
}

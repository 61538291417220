export default class Car {
  constructor(car) {
    Object.assign(this, car)
    this.modelName = car.model
    this.isEco = [
      "hybrid-petrol",
      "hybrid-diesel",
      "electric",
      "hybrid-lpg",
      "hybrid-cng",
      "plugin-hybrid-electric"
    ].includes(car.fuel)
    const url = `/v1/car_model/${car.brandImageUrlPath}/${car.modelImageUrlPath}`
    this.frontImage = url
    this.image = `q_auto,f_auto/v1/car_model/${car.brandImageUrlPath}/${car.modelImageUrlPath}`
    this.imageRear = `${url}_rear`
    this.imageInterior = `${url}_interior`
    this.imageInterior2 = `${url}_interior2`
    this.imageExterior = `${url}_exterior`
    this.imagePanorama = `${url}_360`
  }
}
